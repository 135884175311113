export default {
  countDecimal(num: string): number {
    const numLowerCase = num.toLowerCase()
    if (numLowerCase.includes('e-')) return Number(numLowerCase.split('e-')[1])

    const decimalNum = numLowerCase.split('.')[1]
    if (!decimalNum) return 0

    const decimalNumReverse = decimalNum.split('').reverse().join('')
    if (Number(decimalNumReverse) === 0) return 0

    let countIndex = 0
    while (decimalNumReverse[countIndex] === '0') {
      countIndex++
    }

    return decimalNumReverse.length - countIndex
  },

  getCorrectExchange(exchange: string): string {
    const exchangeUpper = exchange ? exchange.toUpperCase() : ''
    if (exchangeUpper.includes('BINANCE')) return 'BINANCE'

    let res = exchangeUpper.includes('FUTURE') ? exchangeUpper.split('FUTURE')[0] : exchangeUpper
    res = res.includes('TESTNET') ? res.split('TESTNET')[0] : res
    res = res.includes('CROSS') ? res.split('CROSS')[0] : res
    res = res.includes('MARGIN') ? res.split('MARGIN')[0] : res

    return res.toLowerCase()
  },

  deepFloatNumberEqual(num1: number, num2: number, epsilon: number): boolean {
    return Math.abs(num1 - num2) <= epsilon
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// register plugins
import './plugins/element-ui'
import './plugins/font-awesome'
import socket from './plugins/socket'
import '@/assets/styles/index.css'
import filters from './filters'
import './plugins/highchart/index'

// register as global component
import './components'

// directive
import '@/directive/click-outside'

// introduce prototypes
import { WINDOW_SIZE } from './constants'
Vue.prototype.$static = {
  windowSize: WINDOW_SIZE
}

Vue.prototype.$socket = socket
Vue.prototype.$filters = filters

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

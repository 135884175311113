



























import Vue from 'vue'
import { SelectOption } from '@/models/form'

export default Vue.extend({
  props: {
    currentPage: {
      type: Number,
      default: 1
    },

    total: {
      type: Number,
      required: true
    },

    changeFunc: {
      type: Function,
      required: true
    }
  },

  data(): {
    page: number
    perPage: number
  } {
    return {
      page: this.currentPage,
      perPage: 10
    }
  },

  computed: {
    PER_PAGE_OPTIONS(): SelectOption[] {
      return [
        {
          label: '10',
          value: 10
        },
        {
          label: '25',
          value: 25
        },
        {
          label: '50',
          value: 50
        },
        {
          label: '100',
          value: 100
        }
      ]
    },

    allowPrevPage(): boolean {
      return this.page !== 1
    },

    allowNextPage(): boolean {
      return this.total > 1 && this.page < this.total
    }
  },

  methods: {
    go(page: number) {
      this.page += page
    },

    changeHandler() {
      this.changeFunc()
    }
  },

  watch: {
    page(val: number) {
      this.$emit('update:currentPage', val)
      this.changeFunc()
    },

    perPage() {
      this.changeFunc()
    }
  }
})

import globalConfig from '@/global-config'

import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { RootState } from './types'
import auth from './modules/auth'
import message from './modules/message'
import exchange from './modules/exchange'
import flagTrigger from './modules/flag-trigger'
import editOrderModal from './modules/edit-order-modal'

const debug = !globalConfig.isProd

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    message,
    exchange,
    flagTrigger,
    editOrderModal
  },

  strict: debug
}

export default new Vuex.Store<RootState>(store)

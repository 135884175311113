






import Vue from 'vue'

export enum SIZES {
  MEDIUM = 'md'
}

export default Vue.extend({
  props: {
    size: {
      type: String as () => SIZES,
      default: SIZES.MEDIUM
    },

    noPad: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    SIZES() {
      return SIZES
    }
  }
})

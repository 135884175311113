







import Vue from 'vue'
import Toast from '@/components/Toast.vue'

export default Vue.extend({
  components: {
    Toast
  },

  beforeDestroy() {
    this.$socket.disconnect()
  }
})

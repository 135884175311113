import { TableRecord } from '@/views/DashBoard/types'

export interface EditOrderModalState {
  open: boolean
  orderData: TableRecord | {}
}

export const EDIT_ORDER_MODAL_TYPES = {
  OPEN_EDIT_ORDER_MODAL: 'SET_EDIT_ORDER_MODAL',
  CLOSE_EDIT_ORDER_MODAL: 'CLOSE_EDIT_ORDER_MODAL'
}

import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { MessageState, MESSAGE_STATUS, MESSAGE_MUTATION_TYPES } from './types'

const state: MessageState = {
  type: MESSAGE_STATUS.SUCCESS,
  message: ''
}

const mutations = {
  [MESSAGE_MUTATION_TYPES.SET_MESSAGE]: (state: MessageState, payload: MessageState): void => {
    state.message = payload.message
    state.type = payload.type
  },

  [MESSAGE_MUTATION_TYPES.REMOVE_MESSAGE]: (state: MessageState): void => {
    state.message = ''
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<MessageState, RootState>

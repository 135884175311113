import globalConfig from '@/global-config'

import { io } from 'socket.io-client'
import StorageKeys from '@/constants/storage-keys'

const socket = io(globalConfig.VUE_APP_WS_HOST, {
  path: '/iron-gate',
  transports: ['websocket']
})

socket.on('error', (err) => {
  console.log(err)
})

socket.on('connect', () => {
  // get jwt token
  const authToken = localStorage.getItem(StorageKeys.authProfile)
  if (authToken) {
    console.log('-------------------------------- socket connect successfully --------------------------------')
    socket.emit('AUTH', {
      data: {
        authorization: authToken
      }
    })
  }
})

export default socket

import { Module } from 'vuex'
import { RootState } from '../../types'
import { ExchangeState, EXCHANGE_MUTATION_TYPES } from './types'
import { ExchangeRefInstrument } from '@/services/exchange/models'
import helpers from '@/utils/helpers'

const state: ExchangeState = {
  refInstrument: [],
  exchange: '',
  market: '',
  pair: undefined
}

const mutations = {
  [EXCHANGE_MUTATION_TYPES.SET_REF_INSTRUMENT](state: ExchangeState, payload: ExchangeRefInstrument[]) {
    state.refInstrument = payload
  },

  [EXCHANGE_MUTATION_TYPES.SET_CURRENT_EXCHANGE](state: ExchangeState, payload: string) {
    state.exchange = payload
  },

  [EXCHANGE_MUTATION_TYPES.SET_CURRENT_MARKET](state: ExchangeState, payload: string) {
    state.market = payload
  },

  [EXCHANGE_MUTATION_TYPES.SET_CURRENT_PAIR](state: ExchangeState, payload: ExchangeRefInstrument) {
    state.pair = payload
  }
}

const getters = {
  priceDecimal(state: ExchangeState): number {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!state.pair?.tickSize) {
      return helpers.countDecimal(String(state.pair?.tickSize))
    }

    return 0
  },

  sizeDecimal(state: ExchangeState): number {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!state.pair?.lotSize) {
      return helpers.countDecimal(String(state.pair?.lotSize))
    }

    return 0
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
} as Module<ExchangeState, RootState>






import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { MESSAGE_MUTATION_TYPES } from '@/store/modules/message/types'

export default Vue.extend({
  data(): {
    messageTimeout?: any
  } {
    return {
      messageTimeout: undefined
    }
  },

  computed: {
    ...mapState('message', ['type', 'message'])
  },

  methods: {
    ...mapMutations('message', [MESSAGE_MUTATION_TYPES.REMOVE_MESSAGE]),

    showMessage() {
      this.$message({
        message: this.message,
        type: this.type,
        duration: 4000,
        showClose: true
      })
    },

    clearMessage() {
      this.messageTimeout = setTimeout(() => {
        ;(this as any).REMOVE_MESSAGE()
      }, 4000)
    }
  },

  beforeDestroy() {
    this.messageTimeout && window.clearTimeout(this.messageTimeout)
  },

  watch: {
    message(val) {
      if (!val) return
      this.showMessage()
      this.clearMessage()
    }
  }
})

import { Module } from 'vuex'
import { RootState } from '../../types'
import { FlagTriggerState, FLAG_TRIGGER_MUTATIONS } from './types'

const state: FlagTriggerState = {
  orderFlag: false
}

const mutations = {
  [FLAG_TRIGGER_MUTATIONS.TOGGLE_ORDER_TRIGGER]: (state: FlagTriggerState) => {
    state.orderFlag = !state.orderFlag
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<FlagTriggerState, RootState>

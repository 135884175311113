import moment from 'moment'

export default {
  /* ---------------------- date time format ---------------------------- */
  moment_llll(value: string | number): string {
    return `${moment(new Date(value)).format('llll')}`
  },

  moment_lts(value: string | number): string {
    return `${moment(new Date(value)).format('LTS')}`
  },

  /* ------------------------- number -------------------------------- */

  toShortNumberFormat(num: number) {
    const units = ['k', 'm', 'b', 't', 'q']
    const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length)
    const numFormat = Math.abs(Number(num)) / Number(Number(`1.0e+${unit - (unit % 3)}`).toFixed(0))
    const suffix = units[Math.floor(unit / 3) - 1] || ''

    return `${numFormat.toFixed(1)}${suffix}`
  },

  toFloatNumberWithCommas(num: number, decimal = 2) {
    if (num === 0) return 0
    if (decimal === 0) return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const floatNumArr = parseFloat(String(num)).toFixed(decimal).split('.')
    const shortDecimalNums = Number(floatNumArr[1].split('').reverse().join(''))
    const decimalNumsFormatted = shortDecimalNums === 0 ? 0 : String(shortDecimalNums).split('').reverse().join('')

    return decimalNumsFormatted === 0
      ? `${floatNumArr[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : `${floatNumArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalNumsFormatted}`
  },

  toLongFloatNumberWithCommas(num: number, decimal = 2) {
    const numFormatSplitted = num.toFixed(decimal).toString().split('.')
    return decimal === 0
      ? `${numFormatSplitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      : `${numFormatSplitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${numFormatSplitted[1]}`
  }
}

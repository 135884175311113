import { UserProfile } from '@/services/auth/models'

export interface AuthState {
  user: UserProfile | null
}

export const AUTH_MUTATION_TYPES = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  REMOVE_CURRENT_USER: 'REMOVE_CURRENT_USER'
}

import globalConfig from '@/global-config'

import axios, { AxiosRequestConfig } from 'axios'
import StorageKeys from '@/constants/storage-keys'
import router from '@/router'
import store from '@/store'
import { AUTH_MUTATION_TYPES } from '@/store/modules/auth/types'
import { MessageState, MESSAGE_STATUS, MESSAGE_MUTATION_TYPES } from '@/store/modules/message/types'

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.baseURL = globalConfig.VUE_APP_API_ENDPOINT
    config.headers.Accept = 'application/json'

    const authToken = localStorage.getItem(StorageKeys.authProfile)
    if (authToken) config.headers.Authorization = `Bearer ${authToken}`

    return config
  },

  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    return response
  },

  (error) => {
    store.commit(
      `message/${MESSAGE_MUTATION_TYPES.SET_MESSAGE}`,
      {
        type: MESSAGE_STATUS.ERROR,
        message: error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : 'Have occurred error. Try it again'
      } as MessageState,
      {
        root: true
      }
    )

    if (error.response && error.response.status === 401) {
      localStorage.removeItem(StorageKeys.authProfile)
      store.commit(`auth/${AUTH_MUTATION_TYPES.REMOVE_CURRENT_USER}`, { root: true })
      router.push('/login')
    }

    return Promise.reject(error)
  }
)

export default axios

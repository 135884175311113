import { Module } from 'vuex'
import { RootState } from '../../types'
import { AuthState, AUTH_MUTATION_TYPES } from './types'
import { UserProfile } from '@/services/auth/models'

const state: AuthState = {
  user: null
}

const mutations = {
  [AUTH_MUTATION_TYPES.SET_CURRENT_USER]: (state: AuthState, payload: UserProfile): void => {
    state.user = payload
  },

  [AUTH_MUTATION_TYPES.UPDATE_CURRENT_USER]: (state: AuthState, payload: Partial<UserProfile>): void => {
    state.user = {
      ...state.user,
      ...payload
    }
  },

  [AUTH_MUTATION_TYPES.REMOVE_CURRENT_USER]: (state: AuthState): void => {
    state.user = null
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<AuthState, RootState>

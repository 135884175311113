import axios from '@/plugins/axios'
import { BaseResponse } from '../base'
import { UserLoginReq, UserProfile } from './models'

const RESOURCES = Object.freeze({
  login: '/user/auth/login',
  logout: '/user/auth/logout',
  userProfile: '/user/auth/me'
})

export default {
  login(data: UserLoginReq) {
    return axios.post<BaseResponse<string>>(RESOURCES.login, data)
  },
  logout() {
    return axios.delete<BaseResponse<string>>(RESOURCES.logout)
  },

  getUserProfile() {
    return axios.get<BaseResponse<UserProfile>>(RESOURCES.userProfile)
  }
}

export enum MESSAGE_STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error'
}

export interface MessageState {
  message: string
  type: MESSAGE_STATUS
}

export const MESSAGE_MUTATION_TYPES = {
  SET_MESSAGE: 'SET_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE'
}

import _ from 'lodash'

declare const window: any

const parseEnv = (key: string) => _.get(process.env, key, window[key])

const EnvMap = {
  local: 'local',
  dev: 'dev',
  prod: 'prod'
} as any

const globalConfig = {
  all: {
    VUE_APP_NAME: 'Alphaio Trading'
  },
  local: {
    VUE_APP_API_ENDPOINT: 'https://dev-api.alphaio.capital',
    VUE_APP_WS_HOST: 'https://dev-api.alphaio.capital'
  },
  dev: {
    VUE_APP_API_ENDPOINT: 'https://dev-api.alphaio.capital',
    VUE_APP_WS_HOST: 'https://dev-api.alphaio.capital'
  },
  prod: {
    isProd: true,
    VUE_APP_API_ENDPOINT: 'https://api.crypto.klaride.com',
    VUE_APP_WS_HOST: 'https://api.crypto.klaride.com'
  }
} as any

const RUN_ENV = EnvMap[window.VUE_APP_RUN_ENV]

const config = _.merge(globalConfig.all, globalConfig[RUN_ENV], {
  VUE_APP_API_ENDPOINT: parseEnv('VUE_APP_API_ENDPOINT'),
  VUE_APP_WS_HOST: parseEnv('VUE_APP_WS_HOST'),
  VUE_APP_CAPTCHA_SITEKEY: parseEnv('VUE_APP_CAPTCHA_SITEKEY')
})

export default config

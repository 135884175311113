import Vue, { VNodeDirective } from 'vue'

Vue.directive('click-outside', {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el: HTMLElement, binding: VNodeDirective, vnode: any) {
    const clickOutsideEvent = (event: MouseEvent) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target as Node))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression as string](event)
      }
    }

    document.body.addEventListener('click', clickOutsideEvent)
    // eslint-disable-next-line no-unused-expressions
    vnode.context?.$once('hook:unbind', () => {
      document.body.removeEventListener('click', clickOutsideEvent)
    })
  }
})

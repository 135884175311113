import globalConfig from '@/global-config'

import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import AuthService from '@/services/auth'
import StorageKeys from '@/constants/storage-keys'
import store from '@/store'
import { AuthState, AUTH_MUTATION_TYPES } from '@/store/modules/auth/types'

const appName = globalConfig.VUE_APP_NAME
const lazyLoadRoute = (pageName: string) => {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${pageName}`)
}

const lazyLoadLayout = (pageName: string) => {
  return () => import(/* webpackChunkName: "[request]" */ `@/layouts/${pageName}`)
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: lazyLoadLayout('AuthLayout'),
    meta: { title: `Login - ${appName}` },
    children: [
      {
        path: '',
        name: 'login',
        component: lazyLoadRoute('Auth/Login')
      }
    ]
  },

  {
    path: '/',
    component: lazyLoadLayout('default'),
    children: [
      { path: '/', redirect: '/dashboard' },
      { path: '/smart-trading', redirect: '/smart-trading/trades-terminal' },
      {
        path: '/smart-trading/trades-terminal',
        name: 'TradesTerminal',
        component: lazyLoadRoute('SmartTrading/TradesTerminal'),
        meta: { title: `Trades Terminal - ${appName}` }
      },
      {
        path: '/smart-trading/positions',
        name: 'Positions',
        component: lazyLoadRoute('SmartTrading/Positions'),
        meta: { title: `Positions - ${appName}` }
      },
      {
        path: '/smart-trading/orders',
        name: 'Orders',
        component: lazyLoadRoute('SmartTrading/OpenOrder'),
        meta: { title: `Orders - ${appName}` }
      },
      {
        path: '/smart-trading/smart-trade',
        name: 'SmartTrade',
        component: lazyLoadRoute('SmartTrading/SmartTrade'),
        meta: { title: `Smart Trade - ${appName}` }
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: lazyLoadRoute('DashBoard'),
        meta: { title: `Dash Board - ${appName}` }
      },
      {
        path: '/my-exchanges',
        name: 'MyExchanges',
        component: lazyLoadRoute('MyExchanges'),
        meta: { title: `My Exchanges - ${appName}` }
      }
    ]
  },

  {
    path: '/me',
    component: lazyLoadLayout('default'),
    children: [
      {
        path: 'portfolios',
        name: 'MyPortfolios',
        component: lazyLoadRoute('Me/Portfolios'),
        meta: { title: `Portfolios - ${appName}` }
      },
      {
        path: 'balance-history',
        name: 'BalanceHistory',
        component: lazyLoadRoute('Me/BalanceHistory'),
        meta: { title: `Balance History - ${appName}` }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: lazyLoadRoute('Me/Settings'),
        meta: { title: `Settings - ${appName}` }
      }
    ]
  },

  {
    path: '*',
    name: 'error-404',
    component: lazyLoadRoute('NotFound'),
    meta: { title: `Smart Trade - ${appName}` }
  }
]

const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exacted',
  base: globalConfig.BASE_URL,
  routes
})

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to: Route, _, next) => {
  const authProfileToken = localStorage.getItem(StorageKeys.authProfile)
  if (authProfileToken) {
    const currentUserProfile = (store.state.auth as AuthState).user
    if (!currentUserProfile) {
      try {
        const { data: profileRes } = await AuthService.getUserProfile()
        if (profileRes.success)
          store.commit(`auth/${AUTH_MUTATION_TYPES.SET_CURRENT_USER}`, profileRes.data, { root: true })
      } catch (_) {}
    }

    if (to.name === 'login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.name === 'login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router

import { Module } from 'vuex'
import { TableRecord } from '@/views/DashBoard/types'
import { RootState } from '../../types'
import { EditOrderModalState, EDIT_ORDER_MODAL_TYPES } from './type'

const state: EditOrderModalState = {
  open: false,
  orderData: {}
}

const mutations = {
  [EDIT_ORDER_MODAL_TYPES.OPEN_EDIT_ORDER_MODAL]: (state: EditOrderModalState, payload: TableRecord): void => {
    state.open = true
    state.orderData = payload
  },

  [EDIT_ORDER_MODAL_TYPES.CLOSE_EDIT_ORDER_MODAL]: (state: EditOrderModalState): void => {
    state.open = false
    state.orderData = {}
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<EditOrderModalState, RootState>

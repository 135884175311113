import { ExchangeRefInstrument } from '@/services/exchange/models'

export interface ExchangeState {
  refInstrument: ExchangeRefInstrument[]
  exchange: string
  market: string
  pair?: ExchangeRefInstrument
}

export const EXCHANGE_MUTATION_TYPES = {
  SET_REF_INSTRUMENT: 'SET_REF_INSTRUMENT',
  SET_CURRENT_EXCHANGE: 'SET_CURRENT_EXCHANGE',
  SET_CURRENT_PAIR: 'SET_CURRENT_PAIR',
  SET_CURRENT_MARKET: 'SET_CURRENT_MARKET'
}
